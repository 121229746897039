import { domain } from "~/shared/domain";

export const metaBuilder = ({
  title,
  description,
  url,
  imageUrl,
  imageAlt,
}: {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  imageAlt: string;
}) => {
  return [
    { title: title },
    {
      name: "description",
      content: description,
    },
    {
      tagName: "link",
      rel: "canonical",
      href: `${domain()}${url}`,
    },
    {
      property: "mobile-web-app-capable",
      content: 'yes',
    },
    {
      property: "apple-mobile-web-app-capable",
      content: 'yes',
    },
    {
      property: "apple-mobile-web-app-status-bar-style",
      content: 'black-translucent',
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:type",
      content: 'website',
    },
    {
      property: "og:url",
      content: `${domain()}${url}`,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:site_name",
      content: "Recipes by Big Horn Olive Oil",
    },
    {
      property: "og:image",
      content: `${domain()}${imageUrl}`,
    },
    {
      property: "og:image:secure_url",
      content: `${domain()}${imageUrl}`,
    },
    {
      property: "og:image:alt",
      content: imageAlt,
    },
    {
      property: "twitter:card",
      content: 'summary',
    },
    {
      property: "twitter:title",
      content: title,
    },
    {
      property: "twitter:description",
      content: description,
    },
    {
      property: "twitter:image",
      content: `${domain()}${imageUrl}`,
    },
    {
      property: "twitter:image:alt",
      content: imageAlt,
    },
  ];
};
